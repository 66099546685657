import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { ContentfulPage, ContentfulProduct } from '../../types/graphql-types'
import SEO from '../components/SEO'
import Reviews from '../components/Reviews'
import Layout from '../components/Layout/Layout'

interface Props {
  data: {
    contentfulPage: ContentfulPage
  }
}

export const query = graphql`
  query ReviewsTest {
    contentfulPage(externalName: { eq: "Reviews Test Page" }) {
      ...productPage
    }
  }
`

const ReviewsTestPage: React.FC<Props> = ({ data }: Props) => {
  const productName = [
    'Jitterbug Smart3',
    'Jitterbug Flip2',
    'Lively Mobile Plus',
  ]
  const [product, setProduct] = useState(productName[0])

  const deviceDetails = (
    data.contentfulPage?.references as ContentfulProduct[]
  )?.find(content => content?.deviceName === product)

  return (
    <Layout>
      <SEO
        title={data.contentfulPage?.seoTitle as string}
        description={data.contentfulPage?.seoDescription as string}
        noIndex={true}
      />
      <section className={`gradient-3-bby mx-auto max-w-1920 md:h-64`}>
        <div className="flex h-full flex-wrap content-center">
          <div className={`container py-12 text-left text-white`}>
            <h1 className={`mb-4 leading-none`}>Reviews</h1>
          </div>
        </div>
      </section>
      <div className="content-section-full py-6 text-center">
        <select
          onChange={e => setProduct(e.target.value)}
          defaultValue={product}
          className="bg-lively-gray-3"
        >
          {productName.map((option, idx) => (
            <option key={idx}>{option}</option>
          ))}
        </select>
      </div>
      <>
        {(data.contentfulPage?.references as ContentfulProduct[])?.map(
          (content, index) => {
            let reviews

            if (content?.deviceName === product) {
              reviews = (
                <Reviews
                  key={index}
                  data={data}
                  deviceDetails={deviceDetails}
                />
              )
            }

            return [reviews]
          }
        )}
      </>
    </Layout>
  )
}

export default ReviewsTestPage
